import { Head } from 'components/Head'
import Layout from 'components/Layout'
import SplashPage from 'pages/components/SplashPage'

import { getAllChainCollections } from 'utils/multichain'

const Home = () => {
  const collections = getAllChainCollections()

  return (
    <Layout>
      <Head />
      <SplashPage collections={collections} />
    </Layout>
  )
}

export default Home
